export const LOCAL_SERVER_STAG_BASE_URL = "http://192.168.100.2:8046";
// export const LOCALHOST_BASE_URL = "https://app.commune.com.pk";
export const LOCALHOST_BASE_URL = "https://localhost:7280";
 
// export const Hostgator_BASE_URL = "http://18.141.38.194:1133";
export const AWS_BASE_URL = "https://app.commune.com.pk";
export const CACHE_CONTROL = "no-cache, no-store, must-revalidate";
export const PRAGMA = "no-cache";
// 

// https://localhost:44377
